import {ReactElement, useEffect, useState} from 'react';
import dynamic from 'next/dynamic';
import Layout from '../components/Layout';
import {useAppDispatch, useAppSelector} from '../hooks/redux';
import {IOffice} from '../interfaces';
import {getOfficePaymentOptions} from '../services/payment-options.service';
import moment from 'moment/moment';
import {useRouter} from 'next/router';
import AuthService from './api/auth';

const ProductsList = dynamic(() => import('../components/ProductsList/ProductsList'));
const Sales        = dynamic(() => import('../components/Sales/Sales'));

const IndexPage = () => {
    const dispatch                             = useAppDispatch();
    const office: IOffice                      = useAppSelector((state) => state.office);
    const {options: paymentOptions, isLoading} = useAppSelector((state) => state.paymentOptions);
    const [isSalesExpanded, setIsExpanded]     = useState(false);
    const router                               = useRouter();

    useEffect(() => {
        if (router.query['from'] === 'prodesk') {
            redirectProdesk();
        }
    }, [router.isReady]);

    useEffect(() => {
        checkTimestampLocalStorage();
        setIsExpanded(JSON.parse(sessionStorage.getItem('isSalesExpanded')));
    }, []);

    useEffect(() => {
        if (paymentOptions.length === 0 && office.id) {
            !isLoading && dispatch(getOfficePaymentOptions(office.id));
        }
    }, [office]);

    const redirectProdesk = () => {
        const authService = new AuthService();
        const accessToken = authService.getAccessToken();
        window.open(`${process.env.PRODESK_BASE_URL}/?token=${accessToken}`, '_self');
    };

    const checkTimestampLocalStorage = () => {
        const timestamp = localStorage.getItem('pos.prodalam.timestamp');
        !moment.unix(Number(timestamp)).isSame(moment(), 'day') && cleanLocalStorage();
    };

    const cleanLocalStorage = () => {
        localStorage.removeItem('pos.prodalam.carts');
        Object.keys(localStorage).forEach(function (key) {
            if (key.includes('pos.prodalam.sale') || key.includes('pos.prodalam.quote') || key.includes('pos.prodalam.order')) {
                localStorage.removeItem(key);
            }
        });
        localStorage.setItem('pos.prodalam.timestamp', moment().unix().toString());
    };

    const isSalesExpandedHandler = () => {
        let value = !isSalesExpanded;
        setIsExpanded(value);
        sessionStorage.setItem('isSalesExpanded', JSON.stringify(value));
    };

    return (
        <div className="flex h-full no-scroll-bar">
            <ProductsList isSalesExpanded={isSalesExpanded}/>
            <Sales isSalesExpanded={isSalesExpanded} setIsSalesExpanded={isSalesExpandedHandler}/>
        </div>
    );
};

IndexPage.getLayout = (page: ReactElement) => (
    <Layout title="POS - Prodalam">
        {page}
    </Layout>
);

export default IndexPage;
